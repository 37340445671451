import { render, staticRenderFns } from "./Pub.vue?vue&type=template&id=c9781686&scoped=true"
import script from "./Pub.vue?vue&type=script&lang=js"
export * from "./Pub.vue?vue&type=script&lang=js"
import style0 from "./Pub.vue?vue&type=style&index=0&id=c9781686&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9781686",
  null
  
)

export default component.exports