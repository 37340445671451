<template>
    <v-container fluid>
        <validation-observer ref="observer" v-slot="{ invalid, validated }">
            <v-toolbar flat>
                <v-toolbar-title
                    ><colored-status :value="statusActive" :tooltip="statusText" />{{
                        $t('oampublication/label--page-title')
                    }}
                    #{{ id }}</v-toolbar-title
                >
                <v-spacer />
                <v-btn color="primary" outlined class="ma-4" @click="close()">
                    {{ $t('oampublication/action--close') }}
                </v-btn>
                <v-btn color="primary" :disabled="invalid || !validated" @click="save()">
                    {{ $t('oampublication/action--save') }}
                </v-btn>
            </v-toolbar>
            <v-card outlined>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-card-text>
                                            <span class="primary--text">
                                                {{ $t('oampublication/label--place-text-hint2') }}
                                            </span>
                                            <br /><br />
                                            <validation-provider
                                                v-slot="{ errors }"
                                                :name="$t('oampublication/label--place-text')"
                                                rules="required"
                                            >
                                                <v-select
                                                    v-model="placeId"
                                                    :items="places"
                                                    item-text="text"
                                                    item-value="id"
                                                    :label="$t('oampublication/label--place-text')"
                                                    :hint="$t('oampublication/label--place-text-hint')"
                                                    :error-messages="errors"
                                                    required
                                                    persistent-hint
                                                    @change="changePlace"
                                                ></v-select>
                                            </validation-provider>
                                            <v-toolbar flat dense class="places-toolbar">
                                                <v-spacer />
                                                <v-btn right outlined color="primary" @click="showDialog()">
                                                    {{ $t('oampublication/label--manage-places') }}
                                                </v-btn>
                                            </v-toolbar>
                                            <JobAdPubConnectorJobCloud
                                                v-if="placeId == -10"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorChMedia
                                                v-if="placeId == -12"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSozjob
                                                v-if="placeId == -13"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorJobup
                                                v-if="placeId == -15"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSpitalstellenmarkt
                                                v-if="placeId == -19"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSpitexjobs
                                                v-if="placeId == -18"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSchuljobs
                                                v-if="placeId == -17"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSozialinfo
                                                v-if="placeId == -20"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorPraxisstellen
                                                v-if="placeId == -21"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorHealthjobs
                                                v-if="placeId == -22"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSwissDevJobs
                                                v-if="placeId == -27"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorSeco
                                                v-if="placeId == -30"
                                                @child-rendered="childRendered()"
                                            />
                                            <JobAdPubConnectorJobiq
                                                v-if="placeId == -35"
                                                @child-rendered="childRendered()"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                                        <template #activator="{ on, attrs }">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                vid="publishedFrom"
                                                rules="required|lt:@publishedTo"
                                                :name="$t('oampublication/label--published-from')"
                                            >
                                                <v-text-field
                                                    :error-messages="errors"
                                                    :value="compPublishedFrom"
                                                    :label="$t('oampublication/label--published-from')"
                                                    clearable
                                                    v-bind="attrs"
                                                    readonly
                                                    v-on="on"
                                                    @click:clear="publishedFrom = null"
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker
                                            v-model="publishedFrom"
                                            no-title
                                            color="primary"
                                            @input="dateMenu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu v-model="dateMenu2" :close-on-content-click="false" max-width="290">
                                        <template #activator="{ on, attrs }">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                vid="publishedTo"
                                                rules="gt:@publishedFrom"
                                            >
                                                <v-text-field
                                                    :error-messages="errors"
                                                    :value="compPublishedTo"
                                                    :label="$t('oampublication/label--published-to')"
                                                    clearable
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click:clear="publishedTo = null"
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker
                                            v-model="publishedTo"
                                            no-title
                                            color="primary"
                                            @input="dateMenu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text--secondary caption">
                        {{ $t('oampublication/label--created-at') }} {{ compCreatedAt }}
                        {{ $t('oampublication/label--created-by') }} {{ createdBy }}
                    </v-col>
                </v-card-actions>
            </v-card>
        </validation-observer>

        <JobAdPubLocationManager v-if="dialog" name="LocationManager" :dialog="dialog" @show-dialog="showDialog()" />

        <Loader :loader="loading" />
    </v-container>
</template>

<script>
import Vue from 'vue'
import { router } from '@/router'
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ColoredStatus from '../../../components/ColoredStatus'

export default {
    components: {
        ColoredStatus,
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        loadingText: Vue.i18n.translate('oamcommon/component--loading'),
        serverError: null,
        loading: true,
        dateMenu: false,
        dateMenu2: false,
        dialog: false,
    }),
    computed: {
        ...mapGetters('place', ['places']),
        ...mapFields('pub', [
            'currentItem.id',
            'currentItem.statusActive',
            'currentItem.statusText',
            'currentItem.placeId',
            'currentItem.publishedFrom',
            'currentItem.publishedTo',
            'currentItem.isActive',
            'currentItem.link',
            'currentItem.applyLink',
            'currentItem.createdBy',
            'currentItem.createdAt',
        ]),
        compCreatedAt() {
            return this.createdAt ? moment(this.createdAt).format('DD.MM.YYYY HH:mm') : ''
        },
        compPublishedFrom() {
            return this.publishedFrom ? moment(this.publishedFrom).format('DD.MM.YYYY') : ''
        },
        compPublishedTo() {
            return this.publishedTo ? moment(this.publishedTo).format('DD.MM.YYYY') : ''
        },
    },
    mounted() {
        if (window.parentIFrame) {
            window.parentIFrame.scrollTo(0, 0)
        }

        this.getItemById(this.$route.params.pub).then(() => {
            this.getPlaces(this.$route.params.pub).then(() => {
                this.getItemExtensions().then(() => {
                    this.loading = false
                    this.$refs.observer.validate()
                })
            })
        })
    },
    methods: {
        ...mapActions('place', { getPlaces: 'getItems' }),
        ...mapActions('pub', ['getItemById', 'editItem', 'getItemExtensions']),
        ...mapActions('alert', ['showSuccess']),

        async childRendered() {
            await this.$refs.observer.validate()
        },

        close() {
            router.push({ name: 'ad' })
        },

        save() {
            this.editItem()
            router.push({ name: 'ad' })
        },

        selectLink(event) {
            event.target.select()
        },

        showDialog() {
            this.dialog = !this.dialog
            this.getPlaces(this.$route.params.pub)
        },

        changePlace() {
            switch (parseInt(this.placeId)) {
                case -35:
                    if (moment().day() === 5 || moment().day() === 6) {
                        // friday or saturday -> show monday
                        this.publishedFrom = moment().day(8).toISOString()
                    } else {
                        // other days, show next day
                        this.publishedFrom = moment().add(1, 'days').toISOString()
                    }
                    this.publishedTo = moment(this.publishedFrom).add(24, 'days').toDate().toISOString()
                    break
                case -27:
                    this.publishedFrom = moment().toISOString()
                    this.publishedTo = moment(this.publishedFrom).add(59, 'days').toISOString()
                    break
                default:
                    this.publishedFrom = moment().toISOString()
                    this.publishedTo = moment(this.publishedFrom).add(29, 'days').toISOString()
            }
        },

        connectorLoader(event) {
            event.target.select()
        },
    },
}
</script>

<style scoped>
.places-toolbar >>> .v-toolbar__content {
    padding: 0px !important;
}
</style>
